const state = {
  locale: 'ja',
  themeMode: 'light',
  themeColor: 'indigo',
  themeImage: '',
  downloadFormat: 'csv',
  downloadEncoding: 'utf-8',
  scheduleType: null,
  scheduleAllDayIncluded: true,
  onlineformCheckKey: null,
  snackbar: {
    show: false,
    text: '',
    color: '',
  },
}

// getters
const getters = {
  getLocale: (state) => state.locale,
  getThemeColor: (state) => state.themeColor,
  getThemeMode: (state) => state.themeMode,
  getSnackbar: (state) => state.snackbar,
  getDownloadFormat: (state) => state.downloadFormat,
  getDownloadEncoding: (state) => state.downloadEncoding,
  getScheduleType: (state) => state.scheduleType,
  getScheduleAllDayIncluded: (state) => state.scheduleAllDayIncluded,
  getOnlineformCheckKey: (state) => state.onlineformCheckKey,
}

// mutations
const mutations = {
  setLocale(state, locale) {
    state.locale = locale
  },
  setThemeColor(state, payload) {
    state.themeColor = payload
  },
  setThemeMode(state, payload) {
    state.themeMode = payload
  },
  setThemeImage(state, payload) {
    state.themeImage = payload
  },
  setDownloadFormat(state, payload) {
    state.downloadFormat = payload
  },
  setDownloadEncoding(state, payload) {
    state.downloadEncoding = payload
  },
  setScheduleType(state, payload) {
    state.scheduleType = payload
  },
  setScheduleAllDayIncluded(state, payload) {
    state.scheduleAllDayIncluded = payload
  },
  setOnlineformCheckKey(state, payload) {
    state.onlineformCheckKey = payload
  },
  showSnackbar(state, { color, text }) {
    state.snackbar.show = true
    state.snackbar.color = color
    state.snackbar.text = text
  },
  hideSnackbar(state) {
    state.snackbar.show = false
  },
}

// actions
const actions = {}

export default {
  namespace: true,
  state,
  getters,
  actions,
  mutations,
}
