<template>
  <v-fab-transition>
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      :style="{
        bottom: fab ? '16px' : '',
      }"
      :aria-label="$t('scroll_top')"
      depressed
      bottom
      fab
      fixed
      right
      :title="$t('scroll_top')"
      class="btn-secondary btn-top"
      @click="toTop"
    >
      <img class="btn-secondary__icon" src="/static/icon/icon_arrow_upward_kgreen600.svg" alt="" />
    </v-btn>
  </v-fab-transition>
</template>

<script>
export default {
  name: 'AppFab',
  data() {
    return {
      fab: false,
    }
  },
  methods: {
    onScroll() {
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || document.documentElement.offsetTop || 0
      this.fab = top > 300
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
  },
}
</script>
